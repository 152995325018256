import React, { useState, useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import { Box, Button, Text, useColorModeValue, VStack, HStack, useTheme, useBreakpointValue } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
 
const CustomDatePicker = ({ dateRange, onDateChange }) => {
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState(dateRange);
  const theme = useTheme();

  useEffect(() => {
    setRange(dateRange);
  }, [dateRange]);

  const handleRangeSelect = (newRange) => {
    setRange(newRange);
    if (newRange?.from && newRange?.to) {
      onDateChange(newRange);
    }
  };

  const textColor = useColorModeValue('gray.700', 'gray.200');
  const mutedTextColor = useColorModeValue('gray.500', 'gray.400');
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const accentColor = theme.colors.purple[500];
  const hoverColor = useColorModeValue('purple.50', 'purple.900');
  const rangeColor = useColorModeValue('purple.100', 'purple.800');
  const rangeDayColor = useColorModeValue('purple.700', 'purple.100');

  const isMobile = useBreakpointValue({ base: true, md: false });

  const formatDate = (date) => {
    return date ? date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'Select date';
  };

  return (
    <Box 
      bg={bgColor} 
      borderRadius="md" 
      borderWidth="1px" 
      borderColor={borderColor} 
      overflow="hidden" 
      width="100%" 
      maxWidth={isMobile ? "100%" : "320px"}
      boxShadow="sm"
    >
      <VStack spacing={0} align="stretch">
        <Box p={4}>
          <DayPicker
            mode="range"
            selected={range}
            onSelect={handleRangeSelect}
            month={month}
            onMonthChange={setMonth}
            styles={{
              months: { justifyContent: 'center' },
              caption: { color: textColor, fontSize: '1rem', fontWeight: 'bold' },
              head_cell: { color: mutedTextColor, fontSize: '0.875rem', fontWeight: 'normal' },
              day: { color: textColor, fontSize: '0.875rem', margin: '1px', borderRadius: '50%' },
              day_outside: { color: mutedTextColor },
              nav_button: { color: accentColor },
              nav_button_previous: { marginRight: '0.5rem' },
              nav_button_next: { marginLeft: '0.5rem' },
              table: { width: '100%' },
            }}
            components={{
              IconLeft: () => <ChevronLeftIcon boxSize={5} />,
              IconRight: () => <ChevronRightIcon boxSize={5} />,
            }}
            modifiersStyles={{
              selected: { 
                backgroundColor: accentColor,
                color: 'white',
                fontWeight: 'bold'
              },
              range_start: { borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%' },
              range_end: { borderTopRightRadius: '50%', borderBottomRightRadius: '50%' },
              range_middle: { backgroundColor: rangeColor, color: rangeDayColor },
              today: { 
                border: `2px solid ${accentColor}`,
                backgroundColor: 'transparent',
                color: accentColor
              },
              hover: { backgroundColor: hoverColor },
            }}
            modifiers={{
              hover: (day) => !range?.from && !range?.to,
            }}
          />
        </Box>
        <VStack spacing={3} p={4} align="stretch" borderTopWidth="1px" borderColor={borderColor}>
          <HStack justifyContent="space-between">
            <Text color={mutedTextColor} fontSize="sm" fontWeight="medium">Start Date:</Text>
            <Text color={textColor} fontWeight="semibold" fontSize="sm">
              {formatDate(range?.from)}
            </Text>
          </HStack>
          <HStack justifyContent="space-between">
            <Text color={mutedTextColor} fontSize="sm" fontWeight="medium">End Date:</Text>
            <Text color={textColor} fontWeight="semibold" fontSize="sm">
              {formatDate(range?.to)}
            </Text>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};

export default CustomDatePicker;
