const wordTimings = [
    { "word": "In", "start": 0.0, "end": 0.6 },
    { "word": "the", "start": 0.6, "end": 1.0 },
    { "word": "misty", "start": 1.0, "end": 2.0 },
    { "word": "canals", "start": 2.0, "end": 3.0 },
    { "word": "of", "start": 3.0, "end": 3.4 },
    { "word": "Venice", "start": 3.4, "end": 4.4 },
    { "word": "a", "start": 4.6, "end": 4.8 },
    { "word": "mysterious", "start": 4.8, "end": 6.0 },
    { "word": "figure", "start": 6.0, "end": 7.0 },
    { "word": "emerges", "start": 7.0, "end": 8.0 },
    { "word": "from", "start": 8.0, "end": 8.4 },
    { "word": "the", "start": 8.4, "end": 8.8 },
    { "word": "shadows", "start": 8.8, "end": 10.0 },
    { "word": "The", "start": 10.2, "end": 10.6 },
    { "word": "cathedral", "start": 10.6, "end": 11.6 },
    { "word": "holds", "start": 11.6, "end": 12.2 },
    { "word": "secrets", "start": 12.2, "end": 13.2 },
    { "word": "of", "start": 13.2, "end": 13.6 },
    { "word": "the", "start": 13.6, "end": 14.0 },
    { "word": "past", "start": 14.0, "end": 15.0 },
    { "word": "Fog", "start": 15.2, "end": 15.8 },
    { "word": "shrouds", "start": 15.8, "end": 16.8 },
    { "word": "the", "start": 16.8, "end": 17.2 },
    { "word": "ancient", "start": 17.2, "end": 18.2 },
    { "word": "city", "start": 18.2, "end": 19.0 },
    { "word": "streets", "start": 19.0, "end": 20.0 },
    { "word": "A", "start": 20.2, "end": 20.6 },
    { "word": "supernatural", "start": 20.6, "end": 22.0 },
    { "word": "presence", "start": 22.0, "end": 23.0 },
    { "word": "commands", "start": 23.0, "end": 24.0 },
    { "word": "the", "start": 24.0, "end": 24.4 },
    { "word": "room", "start": 24.4, "end": 25.4 },
    { "word": "Whispers", "start": 26.0, "end": 27.0 },
    { "word": "echo", "start": 27.0, "end": 28.0 },
    { "word": "through", "start": 28.0, "end": 28.6 },
    { "word": "empty", "start": 28.6, "end": 29.6 },
    { "word": "corridors", "start": 29.6, "end": 31.0 },
    { "word": "Moonlight", "start": 31.4, "end": 32.4 },
    { "word": "casts", "start": 32.4, "end": 33.2 },
    { "word": "eerie", "start": 33.2, "end": 34.0 },
    { "word": "shadows", "start": 34.0, "end": 35.0 },
    { "word": "on", "start": 35.0, "end": 35.4 },
    { "word": "stone", "start": 35.4, "end": 36.2 },
    { "word": "walls", "start": 36.2, "end": 37.0 },
    { "word": "Secrets", "start": 37.4, "end": 38.4 },
    { "word": "lie", "start": 38.4, "end": 39.0 },
    { "word": "hidden", "start": 39.0, "end": 40.0 },
    { "word": "beneath", "start": 40.0, "end": 40.8 },
    { "word": "murky", "start": 40.8, "end": 41.6 },
    { "word": "waters", "start": 41.6, "end": 42.6 },
    { "word": "Ghostly", "start": 42.8, "end": 43.8 },
    { "word": "reflections", "start": 43.8, "end": 45.4 },
    { "word": "dance", "start": 45.4, "end": 46.2 },
    { "word": "in", "start": 46.2, "end": 46.6 },
    { "word": "rippling", "start": 46.6, "end": 47.6 },
    { "word": "pools", "start": 47.6, "end": 48.6 },
    { "word": "Time", "start": 48.8, "end": 50.0 }

  ];
  
  export default wordTimings;