// src/pages/GoogleAuthCallback.js
import React, { useEffect } from 'react';

function GoogleAuthCallback() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token && window.opener) {
      window.opener.postMessage({ token }, window.location.origin);
      window.close();
    }
  }, []);

  return (
    <div>
      <p>Processing authentication...</p>
    </div>
  );
}

export default GoogleAuthCallback;
