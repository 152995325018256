import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner, Flex, Text } from '@chakra-ui/react';

function AuthCallback() {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    const handleAuthCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get('code');
      const state = urlParams.get('state'); // If using state parameter
      const storedState = sessionStorage.getItem('pkce_state');
      const codeVerifier = sessionStorage.getItem('pkce_code_verifier');

      // Verify state parameter to prevent CSRF attacks
      if (state !== storedState) {
        setError('Invalid state parameter.');
        return;
      }

      if (authorizationCode && codeVerifier) {
        try {
          const tokenResponse = await axios.post('https://api.clipzy.ai/video/token', {
            code: authorizationCode,
            code_verifier: codeVerifier,
            redirect_uri: 'http://localhost:3000/auth/callback', // Must match Redirect URI
            client_id: 'YOUR_GOOGLE_CLIENT_ID', // Replace with your Client ID
            grant_type: 'authorization_code'
          });

          const { token } = tokenResponse.data;
          localStorage.setItem('token', token);

          // Optionally, fetch user details here
          // const userResponse = await axios.get('https://api.clipzy.ai/current', { headers: { Authorization: `Bearer ${token}` } });
          // localStorage.setItem('userDetails', JSON.stringify(userResponse.data));

          navigate('/dashboard');
        } catch (err) {
          console.error('Token exchange failed:', err);
          setError('Token exchange failed. Please try again.');
        }
      } else {
        setError('Authorization code or code verifier missing.');
      }
    };

    handleAuthCallback();
  }, [navigate]);

  if (error) {
    return (
      <Flex align="center" justify="center" minHeight="100vh">
        <Text color="red.500" fontSize="xl">{error}</Text>
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="center" minHeight="100vh">
      <Spinner size="xl" />
      <Text ml={4}>Authenticating...</Text>
    </Flex>
  );
}

export default AuthCallback;