export const audioData = [
    {
      audio_type: "audio",
      audio_name: "Bladerunner 2049",
      audio_description: "Futuristic, popular",
      audio_author: "",
      audio_url: "https://strshrt.xyz/assets/66aa0e918347bec8eceb96df/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66aa0e918347bec8eceb96df/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Snowfall",
      audio_description: "Calm, Tiktok, Popular",
      audio_author: "",
      audio_url: "https://strshrt.xyz/assets/66aa0fb28347bec8eceb96e3/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66aa0fb28347bec8eceb96e3/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Another love",
      audio_description: "Romantic, Calm",
      audio_author: "",
      audio_url: "https://strshrt.xyz/assets/66aa10418347bec8eceb96f1/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66aa10418347bec8eceb96f1/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Else - Paris",
      audio_description: "Suspense, Epic",
      audio_author: "",
      audio_url: "https://strshrt.xyz/assets/66ae64e5d4dd8e5e64104ba5/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae64e5d4dd8e5e64104ba5/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "String Arpeggios",
      audio_description: "Violin, Epic",
      audio_author: "Boris Skalsky",
      audio_url: "https://strshrt.xyz/assets/66ae6590d4dd8e5e64104bc6/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae6590d4dd8e5e64104bc6/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "As Long as in the Heart",
      audio_description: "Travel, Calm",
      audio_author: "Yehezkel Raz",
      audio_url: "https://strshrt.xyz/assets/66ae67c2d4dd8e5e64104bfb/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae67c2d4dd8e5e64104bfb/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Für Elise",
      audio_description: "Classical, Piano",
      audio_author: "Ludwig van Beethoven",
      audio_url: "https://strshrt.xyz/assets/66ae6885d4dd8e5e64104c0e/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae6885d4dd8e5e64104c0e/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Prelude in E minor (Op. 28 n°4)",
      audio_description: "Piano, Classical",
      audio_author: "Chopin",
      audio_url: "https://strshrt.xyz/assets/66ae68ffd4dd8e5e64104c1f/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae68ffd4dd8e5e64104c1f/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Carmen",
      audio_description: "Piano, Classical",
      audio_author: "Georges Bizet",
      audio_url: "https://strshrt.xyz/assets/66ae6972d4dd8e5e64104c30/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae6972d4dd8e5e64104c30/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Eureka",
      audio_description: "Violin, Epic",
      audio_author: "Ardie Son",
      audio_url: "https://strshrt.xyz/assets/66ae69c7d4dd8e5e64104c4f/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae69c7d4dd8e5e64104c4f/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "The Sin",
      audio_description: "Epic, Violin, Tragic",
      audio_author: "Francesco D'Andrea",
      audio_url: "https://strshrt.xyz/assets/66ae6a19d4dd8e5e64104c6e/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae6a19d4dd8e5e64104c6e/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Arietta",
      audio_description: "Piano, Classical",
      audio_author: "Edvard Grieg",
      audio_url: "https://strshrt.xyz/assets/66ae6aaed4dd8e5e64104c7f/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae6aaed4dd8e5e64104c7f/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Signal to Noise",
      audio_description: "Dark and melancholic",
      audio_author: "Scott Buckley",
      audio_url: "https://strshrt.xyz/assets/66ae8bded4dd8e5e64104d27/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae8bded4dd8e5e64104d27/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Ghost",
      audio_description: "Scary, Suspense",
      audio_author: "Tim Beek",
      audio_url: "https://strshrt.xyz/assets/66ae8c3ed4dd8e5e64104d2b/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae8c3ed4dd8e5e64104d2b/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Inspiring",
      audio_description: "Motivational, Inspiring",
      audio_author: "",
      audio_url: "https://strshrt.xyz/assets/66ae8d1ad4dd8e5e64104d2e/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66ae8d1ad4dd8e5e64104d2e/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Epic trailer",
      audio_description: "Epic, Classical",
      audio_author: "Volodymyr Piddubnyk",
      audio_url: "https://strshrt.xyz/assets/66afc85ade27265b798d6572/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66afc85ade27265b798d6572/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Underwater Mystery",
      audio_description: "Fast, Mystery",
      audio_author: "Jon Presstone",
      audio_url: "https://strshrt.xyz/assets/66afc975de27265b798d6576/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66afc975de27265b798d6576/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Making A Villain",
      audio_description: "Violin, Epic",
      audio_author: "Jon Presstone",
      audio_url: "https://strshrt.xyz/assets/66afc9dfde27265b798d657b/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66afc9dfde27265b798d657b/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Tension In The Air",
      audio_description: "Serious, Dark, Cinematic",
      audio_author: "Jon Presstone",
      audio_url: "https://strshrt.xyz/assets/66afca68de27265b798d657e/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66afca68de27265b798d657e/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Tranquil Waterfall Sound",
      audio_description: "Cinematic, Dark, Classical, Serious",
      audio_author: "Sinsai Jasila",
      audio_url: "https://strshrt.xyz/assets/66afcb82de27265b798d6581/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66afcb82de27265b798d6581/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Chronicles Of Tears",
      audio_description: "Sad, Ambient, Dark",
      audio_author: "Saowakhon Media",
      audio_url: "https://strshrt.xyz/assets/66afcc11de27265b798d6584/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66afcc11de27265b798d6584/low_volume/audio.mp3",
    },
    {
      audio_type: "audio",
      audio_name: "Winter",
      audio_description: "Classical, Epic",
      audio_author: "Vivaldi",
      audio_url: "https://strshrt.xyz/assets/66afcc96de27265b798d6587/audio.mp3",
      audio_low_volume: "https://strshrt.xyz/assets/66afcc96de27265b798d6587/low_volume/audio.mp3",
    },
  ];
  