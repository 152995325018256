import React, { useState } from 'react';
import { VStack, Heading, Text, SimpleGrid, Box, Button, Flex, useMediaQuery } from '@chakra-ui/react';
import CaptionStylePreview from '../CaptionStylePreview';
import CaptionPreviewCard from '../CaptionPreviewCard';
import captionStyles from '../caption_style.json';
import wordTimings from '../word_timings.json';

const CaptionStep = () => {
    const [selectedCaptionTemplates, setSelectedCaptionTemplates] = useState([]);
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

    const handleCaptionTemplateChange = (templateName) => {
        setSelectedCaptionTemplates((prevSelected) => {
            if (prevSelected.includes(templateName)) {
                // If the clicked template is already selected, deselect it
                return [];
            } else {
                // If a new template is clicked, select only that one
                return [templateName];
            }
        });
    };

    const clearSelection = () => {
        setSelectedCaptionTemplates([]);
    };

    // Only use the first two lines of word timings for the preview
    const previewWordTimings = wordTimings.slice(0, 2);

    return (
        <VStack spacing={6} align="stretch" w="100%" maxW="1200px" mx="auto" px={4}>
            <Heading size="lg">Caption Settings</Heading>
            <Text>Select caption styles for your video</Text>
            <SimpleGrid columns={[2, 3, 4]} spacing={4}>
                {Object.keys(captionStyles).map((styleName) => (
                    <CaptionStylePreview
                        key={styleName}
                        styleName={styleName}
                        isSelected={selectedCaptionTemplates.includes(styleName)}
                        onSelect={() => handleCaptionTemplateChange(styleName)}
                        label={styleName}
                    />
                ))}
            </SimpleGrid>
             
        </VStack>
    );
};

export default CaptionStep;
